.footerCon{
  background-color: #131010;
  color:#fff;
  /* padding: 10px; */
  width: 100%;
  overflow-x: hidden;
}

.hr{
  background-color: #fff; /* Sets the color of the hr */
  height: 1px; /* Thickness of the line */
  border: none; /* Removes any default border */
}

.subscribe_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between input and button */
  background: transparent;
}
.email_input {
  flex: 1;
  padding: 0.8rem 1rem;
  font-size: 16px;
  color: #fff; /* Text color */
  background: #333; /* Dark gray input background */
  border: 1px solid #555; /* Subtle border */
  border-radius: 25px; /* Rounded corners */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.email_input::placeholder {
  color: #aaa; /* Light gray placeholder */
}

.email_input:focus {
  border-color: #66c6ec; /* Light blue border on focus */
  box-shadow: 0 0 8px rgba(102, 198, 236, 0.6); /* Glow effect */
}

.subscribe_button {
  padding: 0.8rem 1.5rem;
  font-size: 16px;
  font-weight: bold;
  color: #fff; /* Button text color */
  background: #136e95; /* Modern blue background */
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.subscribe_button:hover {
  background: #66c6ec; /* Lighter blue on hover */
}

.subscribe_button:active {
  transform: scale(0.98); /* Slight shrink on click */
}

.subscribe_button:focus {
  outline: none;
}

.linkCont{
  display: flex;
  justify-content: space-around;
  gap:5px;
  width: 100%;
}
 

.linkCont p {
  position: relative;
   font-size: 16px; /* Adjust size as needed */
  color: #fff; /* Text color (white, for example) */
  cursor: pointer; /* Pointer cursor for interactivity */
}

.linkCont p::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #66c6ec; /* Color of the underline */
  transition: width 0.3s ease-in-out; /* Smooth animation */
}

.linkCont p:hover::after {
  width: 100%; /* Fully extends the underline on hover */
}

 

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .subscribe_container {
    flex-direction: column;
    gap: 15px; /* Increased gap for stacked layout */
  }

  .email_input {
    width: 100%;
  }

  .subscribe_button {
    width: 100%;
  }
  .yearExp{
     text-align:center;
  }
}