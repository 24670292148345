
.slick-arrow{
    font-size: 3rem;
    cursor: pointer;
    font-weight: normal !important;
    font-family: inherit !important;
    text-decoration: none;        
}

.slider-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
}
.quote-icon{
    font-size: 60px;
}

.slider-text{
    font-size: xx-large;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .slick-arrow{
        font-size: 2rem;
    }
     
    .slider-text{
        font-size: larger;
        text-align: center;
     }

}
  