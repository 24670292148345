.label{
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
 


/* Hide number input steppers */
.noStepper::-webkit-outer-spin-button,
.noStepper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noStepper {
  -moz-appearance: textfield;
}

/* Error styling for input */
.errorInput {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

/* Error text */
.errorText {
  margin-top: 4px;
  font-size: 0.875rem; /* Smaller font size */
}
