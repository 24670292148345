.inputSearch{
  border: none;
  border-bottom:  1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 20px;
}
.inputSearch:hover,
.inputSearch:focus-within{
  box-shadow: none;
  border: none;
  border-bottom:  1px solid #136e95;
}

.checkCursor{
  cursor: pointer!important;
}