.carousel-container {
  position: relative;
  width: 100%; /* Full width */
  margin: 0 auto;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the entire slide without distortion */
  aspect-ratio: 16/6; /* Balanced aspect ratio for banners */
  border-radius: 8px;
}

/* Custom Arrow Styles */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  transition: transform 0.4s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) scale(1.2) rotate(15deg); /* Adds rotation and scale */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

/* Arrow positions */
.next-arrow {
  right: 20px;
}

.prev-arrow {
  left: 20px;
}

/* Dots Styling */
.slick-dots {
  bottom: 15px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #66c6ec;
}

/* Optional: Bounce effect on hover */
@keyframes arrow-bounce {
  0%, 100% {
    transform: translateY(-50%) scale(1);
  }
  50% {
    transform: translateY(-55%) scale(1.2);
  }
}

.custom-arrow:hover {
  animation: arrow-bounce 0.3s ease-in-out;
}

/* Responsive Icon Sizes */

/* X-Large Screens */
@media (min-width: 1200px) {
  .custom-arrow {
    width: 40px;
    height: 40px;
  }
  
  .custom-arrow .arrow-icon {
    font-size: 258px; /* Larger icon size for large screens */
  }
}

/* Large Screens (Desktop) */
@media (max-width: 1199px) {
  .custom-arrow {
    width: 50px;
    height: 50px;
  }
  
  .custom-arrow .arrow-icon {
    font-size: 24px; /* Medium icon size for desktop */
  }
}

/* Medium Screens (Tablet) */
@media (max-width: 768px) {
  .custom-arrow {
    width: 45px;
    height: 45px;
  }
  
  .custom-arrow .arrow-icon {
    font-size: 20px; /* Smaller icon size for tablet */
  }
}

/* Small Screens (Mobile) */
@media (max-width: 480px) {
  /* Hide the arrows on mobile */
  .custom-arrow {
    display: none;
  }
  
  .carousel-image {
    aspect-ratio: 16/9; /* Increase the height for mobile */
  }

  .custom-arrow .arrow-icon {
    font-size: 18px; /* Even smaller icon size for mobile */
  }
}
