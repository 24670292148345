.product-card {
    border-radius: 10px;
    overflow: hidden;
    position: relative; /* To position wishlist and quick view buttons */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-img-container {
    aspect-ratio: 1 / 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
  }
  
  .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }
  
  .price {
    font-size: 1rem;
    color: #333;
  }
  
  .btn-primary {
    background-color: #136e95;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0f5873;
  }
  
  /* Wishlist and Quick View Buttons */
  .action-buttons {
    position: absolute;
    top: 10px;
    right: -50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: right 0.4s ease; /* Smooth transition */
  }

  .action-buttons_fixed {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: right 0.4s ease; /* Smooth transition */
  }
  
  .product-card:hover .action-buttons {
    right: 10px; /* Slide in on hover */
  }
  
  .action-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 8px;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #ff5722;
  }

  .action-button_active {
    background-color: #ff5722;
  }
  
  
  @media (max-width: 768px) {
    .action-buttons {
      right: 10px;      
    }
  }
  