.login-page {
  margin: 0;
  padding: 0;
  height: 100%; 
  width: 100%;
  background: url('../../../Assets/bgLogin.jpg') no-repeat center center/cover;  
  overflow: hidden;
  position: relative;
}

.login-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  z-index: 1;
}

.login-page > *{
  position: relative;
  z-index: 1;
}

/* Card container */
.lcard {
  border-radius: 15px;
  max-width: 400px;
  width: 90%;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  animation: fadeIn 0.8s ease-in-out;
}

/* Form label */
.form-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

/* Input group styling */
.input-group {
  position: relative;
}

.input-group-text {
  background-color: #6a11cb;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  border: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  text-align: left;
}

.form-control:focus {
  border-color: #2575fc;
  box-shadow: 0 0 5px rgba(37, 117, 252, 0.5);
}

/* Password toggle button */
.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6a11cb;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
}

.password-toggle:hover {
  color: #2575fc;
}

/* Remember me and forgot password */
#rememberMe {
  accent-color: #6a11cb;
}

.text-primary {
  color: #6a11cb !important;
}

.text-primary:hover {
  text-decoration: underline;
}

/* Button styling */
.lbtn-primary {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 25px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.lbtn-primary:hover {
  background: linear-gradient(135deg, #2575fc, #6a11cb);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* Signup link */
.text-center a {
  font-weight: bold;
  text-decoration: none;
}

.text-center a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .lcard {
      padding: 20px 15px;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
