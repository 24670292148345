.custom-divider {
    border: 1px solid #808080;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .mserch-input{
    width: 200px!important;
    height: 40px;     
  }

  .image-container {
    position: relative;
    width: 250px;
    height: 180px;
  }
  
  /* Style for the image */
  .upload-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
  
  /* Style for the upload button */
  .upload-btn {
    display: none; /* Initially hidden */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
    z-index: 1; /* Ensure it appears on top of the image */
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Show the button on hover */
  .image-container:hover .upload-btn {
    display: block;
  }


  .product-card {
    position: relative;    
  }
  .delete-icon {
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  .product-card:hover .delete-icon {
    opacity: 1; /* Show on hover */    
  }





  .image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-placeholder {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 2px dashed #ddd;
    border-radius: 10px;
  }
  
  .image-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .image-box {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: contain;    
  }
  
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .image-box:hover .delete-icon {
    background-color: red;
    opacity: 1;
  }
  
.image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
}