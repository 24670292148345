.accordion {
  max-width: auto;
  margin: 20px auto;
 }

.accordionHeader {
  display: flex;
  align-items: center;  
  font-size: 14px;
  font-weight: bold;
  color: #333;
  border: none;
  background: none;
  box-shadow: none;
  transition: transform 0.2s ease, color 0.3s ease;
}

.accordionHeader:hover {
  color: #136e95;
}

.accordionItem > .accordion-button {
  background-color: transparent !important; /* No background on selection */
  color: #333 !important;
  font-size: 14px;
  font-weight: bold;
}

.accordionItem > .accordion-button:focus {
  box-shadow: none !important; /* Remove focus outline */
}

.headerText {
  margin-right: auto;
  font-size: 13px;
  font-weight: 600;
}

.accordionBody {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  padding: 10px;
  border-top: 1px solid #ddd;
  /* background-color: #f8f9fa; */
  border-radius: 0 0 5px 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}
