/* Root container to define layout */
.layout-container {
  display: flex;
  flex-direction: column;
}

/* Make the main content take up available space */
.content {
  flex: 1; /* This ensures the content grows to push the footer down */
  padding: 20px; /* Optional spacing */
}
 