.no_products_container {
  animation: fadeIn 1s ease-in-out;
  padding: 10px;
  text-align: center;
  background-color: #f8d7da;
  border: 2px solid #f5c6cb;
  border-radius: 10px;  
  width:100%;
  padding: 10px;
}

.no_products_text {
  font-size: 2rem;
  color: #d9534f;
  font-weight: bold;
 }

.no_products_subtext {
  color: #6c757d;
  font-size: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* In UserSubCategoryPage.module.css */
.error_message_container {
  text-align: center;
  padding: 30px;
  border: 2px dashed #e74c3c;
  background-color: #fce4e4;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error_message_icon {
  color: #e74c3c;
}

.error_message_text {
  font-size: 24px;
  font-weight: bold;
  color: #e74c3c;
  margin-top: 10px;
}

.error_message_subtext {
  font-size: 16px;
  color: #7f8c8d;
  margin-top: 5px;
}

.hide_on_sm{
  display: block;
 }
.hide_on_lg{
  display: none;
 }
@media (max-width: 767.98px) { /* Small devices (sm and below) */
  .hide_on_sm {
    display: none;
  }
 .hide_on_lg{
    margin-top:10px;
    margin-bottom: 10px;
    display: block;
  }
}

.filter_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f8; /* Light color background */
  color: #5a5a5a; /* Subtle text and icon color */
  border: 1px solid #ddd; /* Light border */
  padding: 0.4rem 0.8rem; /* Smaller padding */
  font-size: 14px; /* Slightly smaller font size */
  font-weight: 500;
  border-radius: 15px; /* Rounded button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.filter_button:hover {
  transform: translateY(-1px); /* Slight upward movement on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle hover effect */
}

.filter_button:active {
  transform: translateY(0); /* No movement on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Restore original shadow */
}

.filter_icon {
  font-size: 16px; /* Smaller icon */
  margin-right: 8px; /* Space between icon and text */
}

.filter_text {
  font-size: 14px;
}
