
.imageContainer {
  text-align: center;  
  position: relative;
  cursor: pointer;
}

.productImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
}
.heading_w, .subheading_w {
  font-size: 14px; /* Adjust font-size accordingly */
  font-weight: 600; /* Keep this as needed */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  cursor: pointer;
}
.subheading_w {
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}
.price_w {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.cursor_w{
  cursor: pointer;
}

.heading_w:hover{
color:#F76B6A;
}

.del_w{
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.del_icon_w{
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  background-color:rgba(0, 0, 0, 0.4);
  color:#fff;
}

.del_icon_w:hover{
  background-color:#F76B6A;
}