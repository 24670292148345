.dynamic-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .dynamic-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .dynamic-button:disabled {
    background-color: #ccc !important;
    color: #666 !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
  
  .button-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  
  .button-icon svg {
    font-size: 1.2rem;
  }
  