.navbar {    
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(0); /* Default position */
    opacity: 1;
  }
.navbar.hidden {
    transform: translateY(-100%);
    opacity: 0;
  }
  
  /* Visible Navbar */
  .navbar.visible {
    transform: translateY(0);
    opacity: 1;
  }