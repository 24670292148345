.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 20px 0;
}

.pageButton,
.navButton {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pageButton:hover,
.navButton:hover {
  background-color: #136e95;
  color: #fff;
}

.active {
  background-color: #136e95;
  color: #fff;
  font-weight: bold;
  border: 1px solid #136e95;
}

.navButton {
  font-size: 16px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.ellipsis {
  padding: 0 8px;
  color: #aaa;
  font-size: 14px;
}
