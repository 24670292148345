
.img1:hover{
    cursor: pointer;
    animation: bounce 1s linear;
  }
  
  .text:hover>.img1 {
    cursor: pointer;
    animation: bounce 1s linear;
  }
  
  .bouncer-icon{
    width: 100%;
    height: 50px;
  }
  
  @keyframes bounce{
    20%,50%,80%,to{
      transform:translateY(0);
    }
    40%{
      transform: translateY(-30px);
    }
    70%{
      transform: translateY(-15px);
    }
    90%{
      transform: translateY(-4px);
    }
  }
  
   