
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* { 
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;  
  font-style: normal;
}

/* Global Scrollbar Styles */
::-webkit-scrollbar {
  width: 08px; /* Slightly larger for a more prominent look */
  height: 12px; /* For horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #ff7eb3, #66c6ec); /* Vibrant gradient */
  border-radius: 20px; /* Fully rounded thumb */
  border: 3px solid transparent; /* Creates a space around the thumb */
  background-clip: padding-box; /* Ensures the border works seamlessly */
  transition: background 0.3s ease, transform 0.2s ease; /* Smooth hover animations */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #f9b62f, #ff7eb3); /* Gradient changes on hover */
  transform: scale(1.2); /* Thumb grows slightly on hover */
}

::-webkit-scrollbar-track {
  background: radial-gradient(circle, #ffffff, #f1f1f1); /* Subtle radial gradient */
  border-radius: 20px; /* Rounded edges */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Adds depth */
}

::-webkit-scrollbar-track:hover {
  background: radial-gradient(circle, #ffffff, #eaeaea); /* Track changes on hover */
}

/* Firefox Scrollbar */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: linear-gradient(135deg, #ff7eb3, #66c6ec) radial-gradient(circle, #ffffff, #f1f1f1); /* Custom thumb and track */
}

/* Smooth Scroll Animation */
html {
  scroll-behavior: smooth; /* Smooth scrolling for the entire page */
}


 
.auto-fill {
  flex-grow: 1;  
  width: 100%;         /* Makes table span full width */
}

.scrollable-table {  
  overflow-y: auto;
  border: 1px solid #ccc;
  position: relative;
}

.scrollable-table table {
  border-collapse: collapse;
  width: 100%;
}

.scrollable-table th {
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.scrollable-table td {
  border-bottom: 1px solid #ddd;
}

.pointer{
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"]:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

input[type="number"] {
  text-align: right;
  transition: box-shadow 0.2s ease-in-out;
}

input[type="number"]:focus {
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  border-color: #007bff;
}

/* Reset Button */
.reset-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 08px 18px; */
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(135deg, #136e95, #0b4b63);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.reset-button .icon {
  margin-right: 4px;
  transition: transform 0.3s ease-in-out;
}

.reset-button:hover {
  background: linear-gradient(135deg, #0b4b63, #136e95);
  box-shadow: 0 4px 10px rgba(19, 110, 149, 0.4);
}

.reset-button:hover .icon {
  transform: rotate(360deg);
}

.reset-button:active {
  transform: scale(0.95);
}

.reset-button:disabled {
  background: #2a2a2a;
  color: #666666;
  cursor: not-allowed;
}

.numInputStep::-webkit-inner-spin-button,
.numInputStep::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.numInputStep {
  -moz-appearance: textfield;
}

.user_nav{
  margin-bottom: 6%;
}
@media only screen and (max-width: 767px) {
  .user_nav{
    margin-bottom: 20%;
  } 
}