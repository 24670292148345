/* Style for better visibility */
.custom-checkbox .form-check-input {
    width: 20px; /* Increase checkbox size */
    height: 20px; /* Increase checkbox size */
    cursor: pointer;
}

.custom-checkbox .form-check-input:checked {
    background-color: #007bff; /* Change to primary blue on check */
    border-color: #007bff;
}

.custom-checkbox .form-check-label {
    font-weight: 500; /* Make text bold */
    font-size: 14px; /* Adjust font size */
    color: #333; /* Ensure text is visible */
    cursor: pointer; /* Make label clickable */
}

/* Hover effect for better interactivity */
.custom-checkbox .form-check-input:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Scrollbar styling */
.scrollable-row {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #007bff #f1f1f1; /* Firefox */
}

.scrollable-row::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
}

.scrollable-row::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Scrollbar color */
    border-radius: 3px;
}

.scrollable-row::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}
