.search-field {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    width: 100%; /* Default width is 100% */
    
  }
  
  .search-field:hover,
  .search-field:focus-within {
    border-color: #136e95;
    box-shadow: 0 0 5px rgba(19, 110, 149, 0.5);
  }
  
  /* Clear Icon Position */
  .clear-icon {
    font-size: 1rem;
    position: absolute;
    right: 10px; /* Adjust position of clear icon */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa;
    transition: color 0.3s ease;
  }
  
  .clear-icon:hover {
    color: #d93025;
  }
  
  /* Search Icon */
  .search-icon {
    font-size: 1.2rem;
    margin-right: 8px;
    color: #aaa;
  }
  
  /* Search Input Styling */
  .search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    background: transparent;
    padding-right: 30px; /* Space for the clear icon */
     
  }
  
  /* Placeholder Styling */
  .search-input::placeholder {
    color: #aaa;
  }
  