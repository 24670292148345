.container {
  padding: 20px;
  background-color: #FFF;
}

.row {
  margin: 0;
}

.imageContainer {
  text-align: center;
  padding: 20px;
  position: relative;
}

.productImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
}

.section {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.subHeading {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #555;
}

.descriptionTabs .nav-link {
  color: #007bff;
  font-weight: bold;
}

.tabLink {
  text-transform: uppercase;
  font-weight: 500;
}

.tabContent {
  padding-top: 15px;
}

.descriptionList {
  list-style: disc;
  padding-left: 20px;
}

.descriptionItem {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.sizePriceList {
  list-style: none;
  padding: 0;
}

.sizePriceItem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  background: #f9f9f9;
  transition: transform 0.2s, box-shadow 0.2s;
}

.sizePriceItem:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.sizeName {
  font-weight: bold;
  color: #333;
}

.price {
  font-weight: bold;
  color: #007bff;
}

.enquiryButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  font-size: 1rem;
  padding: 12px 20px;
  border-radius: 50px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.enquiryButton:hover {
  background-color: #0056b3;
}

.modal-content {
  border-radius: 8px;
}

.modal-header {
  background-color: #007bff;
  color: #fff;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  background-color: #f1f1f1;
}


.no_products_container {
  animation: fadeIn 1s ease-in-out;
  padding: 10px;
  text-align: center;
  background-color: #f8d7da;
  border: 2px solid #f5c6cb;
  border-radius: 10px;  
  width:100%;
  padding: 10px;
}

.no_products_text {
  font-size: 2rem;
  color: #d9534f;
  font-weight: bold;
 }

.no_products_subtext {
  color: #6c757d;
  font-size: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 768px) {
  .imageContainer {
    padding: 5px;
    margin-bottom: 10px;
  }
}
