* {
  margin: 0;
  padding: 0;
}
.navbar {
  padding: 4px;
}

.admin-layout .navbar-toggler {
  display: inline-block;
  border: none;
}
.nav-link {
  color: white;
  font-weight: 400;
  font-size: 16px;
}
.nav-link:hover {
  color: white;
}
.card {
  border-radius: 0%;
}
