.heading {
  color: #333;
  font-weight: 600;
  font-size: 20px;
}

.subheading {
  color: #333;
  font-weight: 600;
  font-size: 13.5px;
  text-align: center;
}
.cursor{
  cursor: pointer;
}
.loginButton{
  width: 150px;
  /* background-color: #f3e6f8; */
}