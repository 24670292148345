.sizePriceContainer {
  max-width: 600px;  
  max-height: 350px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.sizePriceList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sizePriceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 8px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

}

.sizeName {
  font-weight: bold;
  color: #333;
  flex: 1;
}

.price {
  color: #007bff;
  margin-left: 20px;
  flex: 1;
}

.quantityInput {
  width: 80px;  
  flex: 1;
  border-radius: 5px;
  padding: 5px;
}


